import {Inject, Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {LocalStorageService} from '../localstorage/localstorage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import {Dictionary} from 'highcharts';
import {Restangular} from 'ngx-restangular';
import {AppSettings} from '../../app.settings';
import {RESTANGULAR_INTEGRATION_SERVER} from '../../app.module';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  JWT_TOKEN = 'JWTToken';
  REFRESH_TOKEN = 'refreshToken';

  public loginUrl = '';

  public createTeam = false;

  private isIntegrationAdmin = AppSettings.isIntegration();

  constructor(
      private apiService: ApiService,
      private restangular: Restangular,
      private localStorageService: LocalStorageService,
      @Inject(RESTANGULAR_INTEGRATION_SERVER) private integrationRestangular: Restangular,
      private http: HttpClient
  ) { }

  login(userId: string): Promise<any> {
    return this.apiService.post(`admin/userLogin/${userId}`, {});
  }

  loginByUserId(userId: string): void {
    this.login(userId).then(data => {
      const token = data.v3Url.match(/\/token\/.*/);
      window.open(`${window.location.origin}${token}?api=${this.apiService.apiName}`, '_blank');
    });
  }

loginUserPassword(email: string, password: string): Promise<any> {
    if (this.isIntegrationAdmin) {
      return this.integrationRestangular.one('auth/user').customPOST({user_email: email, user_password: password}).toPromise().then((result: Dictionary<any>) => {
        this.setToken(result.session_token, result.refresh_token);
        return result;
      });
    } else {
        return this.apiService.post('auth/user-password', {username: email, password}).then(data => {
          const parts = data.url.split('/').slice(-2);
          this.setToken(parts[0], parts[1]);
          return data;
        });
    }
  }

  public loginValidateCode(phoneNumber: string, code: string): Promise<any> {
    return this.restangular.all('auth').one('mobile', phoneNumber).one('verificationCode', code).get().toPromise().then((data: any) => {
      this.parseTokens(data);
      return data;
    });
  }

  logout(): void {
    localStorage.clear();
    this.apiService.unsetToken();
    this.localStorageService.removeStorage(this.JWT_TOKEN);
    this.localStorageService.removeStorage(this.REFRESH_TOKEN);
  }

  private parseTokens(data: any): void {
    const parts = data.id.split('/').slice(-2);
    this.setToken(parts[0], parts[1]);
  }

  public setToken(token: string, refreshToken = null): void {
    this.apiService.setToken(token);
    this.localStorageService.setStorage(this.JWT_TOKEN, token);
    if (refreshToken) {
      this.localStorageService.setStorage(this.REFRESH_TOKEN, refreshToken);
    }
  }

  deletePhoneNumber(tel: string): Promise<any> {
    return this.restangular.one('mobile', tel).remove().toPromise();
  }

  public decodeJWTToken(jwt = this.localStorageService.getStorage(this.JWT_TOKEN)): Dictionary<any> | null {
    try {
      const helper = new JwtHelperService();
      return helper.decodeToken(jwt);
    } catch (err) {
      return null;
    }
  }

  public getUserId(): string {
    const token = this.decodeJWTToken();
    return token ? token.userId || token.info.user_id : '';
  }

  private getUuid(): string {
    const token = this.decodeJWTToken();
    return token ? token?.info?.uuid || '' : '';
  }

  public isSuperAdmin(): boolean {
    const ADMIN_PERMISSION = 4096;
    const token = this.decodeJWTToken();
    // tslint:disable-next-line:no-bitwise
    return token ? (token.permissionLevel & ADMIN_PERMISSION) !== 0 : false;
  }

  public getCompany(): Promise<Dictionary<any>> {
    return this.integrationRestangular.one('companies').one(this.getUuid()).get().toPromise();
  }

  public getUser(): Promise<Dictionary<any>> {
    return this.integrationRestangular.one('users').one(this.getUserId()).get().toPromise();
  }

  createAccount(user: any): Promise<any> {
    return this.restangular.one('auth').all('create-user-password').post(user).toPromise();
  }

  getRandomPassword(): Promise<any> {
    return this.restangular.one('admin').one('generateInviteExample').get().toPromise().then((result: { randomInvite: any; }) => result.randomInvite);
  }


  sendEmailCode(email: string): Observable<any> {
    return this.http.post(`${AppSettings.getEndpoint()}auth/login-without-pass`, {email: email})
  }

  verifyEmailCode(email: string, code: string): Observable<any> {
    return this.http.post(`${AppSettings.getEndpoint()}auth/login-without-pass/${code}`, {email: email})
  }
}
