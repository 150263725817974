<div class="topline">
    <h1>Broker</h1>
    <mat-form-field>
        <mat-label>Current Broker</mat-label>
        <mat-select (selectionChange)="setBroker($event)">
            <mat-option *ngFor="let broker of brokers" [value]="broker.id">{{broker.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<nav mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let link of links" (click)="gotoTab(link.link)"
       [active]="activeLink == link">{{link.title}}</a>
</nav>
<ng-container *ngIf="selectedBrokerId">
    <span class="notselected">
        Please select a broker so that you can update their profile, agencies, and products.
    </span>
</ng-container>
<ng-container *ngIf="selectedBrokerId">
    <app-broker-profile tabPanel *ngIf="activeLink?.title === 'Profile'"></app-broker-profile>
    <app-broker-agencies tabPanel *ngIf="activeLink?.title === 'Agencies'"></app-broker-agencies>
    <app-broker-products tabPanel *ngIf="activeLink?.title === 'Products'"></app-broker-products>
</ng-container>
