<ng-container *ngIf="members && members.length === 0">
    <span class="warningbox">You do not have any active members. You can only send one time rewards to active members.</span>
</ng-container>
<ng-container *ngIf="members && members.length > 0">
    <div *ngIf="multiple" class="buttons">
        <button mat-raised-button class="selectbutton" color="accent" (click)="selectAll()">Select All</button>
        <button mat-raised-button class="selectbutton" color="accent" (click)="deselectAll()">Deselect All</button>
    </div>
    <mat-form-field class="search">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput type="search" placeholder="Search members..." class="search-input" (input)="filterItems($event)">
    </mat-form-field>
    <div>
        <div class="member" *ngFor="let member of filteredMembers">
            <mat-checkbox *ngIf="multiple" [checked]="member.selected" (click)="memberCheckboxClicked($event, member)"></mat-checkbox>
            <mat-radio-button *ngIf="!multiple" [checked]="member.selected" (click)="memberCheckboxClicked($event, member)"></mat-radio-button>
            <span class="email">{{member.email}}</span>
            <span class="message" >{{member.message}}</span>
        </div>
    </div>
</ng-container>
