import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {CompanyInfo, TeamCompaniesService} from '../../services/teamcompanies/teamcompanies.service';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TeamsService} from '../../services/teams/teams.service';
import {EditCompanyDialogComponent, EditCompanyDialogModel} from '../modal/editcompanydialog/editcompanydialog.component';
import {SnackBarComponent} from '../snackbar/snackbar.component';
import {SetTeamCompanyComponentModel, SetTeamCompanyDialogComponent} from '../modal/setteamcompanydialog/setteamcompanydialog.component';
import {
  EditCompanyManagersDialogComponent,
  EditCompanyManagersDialogModel
} from '../modal/editcompanymanagersdialog/editcompanymanagersdialog.component';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {ExporttocsvService} from '../../services/exporttocsv/exporttocsv.service';
import {Router} from '@angular/router';

interface Node {
  name: string;
  type: string;
  id: string;
  children: Array<Node>;
}

@Component({
  selector: 'app-products-companies',
  templateUrl: './products-companies.component.html',
  styleUrls: ['./products-companies.component.scss']
})
export class ProductsCompaniesComponent implements OnInit {

  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<Node>();
  private companies: Array<CompanyInfo> = [];
  public filter = '';

  public typeIcons = {
    wallit: {name: 'wallit', tooltip: 'Wallit master'},
    carrier: {name: 'business', tooltip: 'Carrier'},
    broker: {name: 'store', tooltip: 'Broker'},
    agency: {name: 'business_center', tooltip: 'Agency'},
    employer: {name: 'badge', tooltip: 'Employer/Client'},
    team: {name: 'receipt_long', tooltip: 'Team'}
  };

  constructor(
      private teamCompaniesService: TeamCompaniesService,
      private uiAlertService: UiAlertService,
      private dialog: MatDialog,
      private snackbar: MatSnackBar,
      private teamsService: TeamsService,
      private exportToCsvService: ExporttocsvService,
      private router: Router
  ) {
  }

  ngOnInit(): void {
    console.log('PRODUCTS');
    this.loadItems();
  }

  getIcon(node: Node): string {
    // @ts-ignore
    return this.typeIcons[node.type]?.name;
  }

  getTooltip(node: Node): string {
    // @ts-ignore
    return this.typeIcons[node.type]?.tooltip;
  }

  private loadItems(): void {
    this.teamCompaniesService.getCompanies().then(companies => {
      this.companies = companies;
      this.dataSource.data = this.transformToNested(companies);
      console.log('data', this.dataSource.data);
      this.treeControl.expand(this.dataSource.data[0]);
    });
  }

  private transformToNested(data: any[], parentId = null): any[] {
    if (this.filter) {
      return data;
    }
    return data.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }).filter(item => item.parentId === parentId)
        .map(item => ({
          ...item,
          children: this.transformToNested(data, item.id)
        }));
  }

  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  clearFilter(): void {
    this.filter = '';
    this.dataSource.data = this.transformToNested(this.companies);
  }

  applyFilter(event: any): void {
    let value = event.target.value;
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.data = this.transformToNested(this.companies.filter(company => company.name.toLowerCase().includes(value)));
  }

  addCompany(item?: Node): void {
    const dialogRef = this.dialog.open(EditCompanyDialogComponent, {
      data: new EditCompanyDialogModel({}, undefined)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.teamCompaniesService.createCompany(dialogResult.name, dialogResult.payrollConfig, dialogResult.domainName, dialogResult.type, item?.id || '',
          dialogResult.EIN, dialogResult.SIC, dialogResult.address1, dialogResult.address2, dialogResult.city, dialogResult.state, dialogResult.postcode).then (company => {
        this.teamCompaniesService.uploadLogo(company.id, dialogResult.imageData).then(result => {
          this.teamCompaniesService.modifyCompany(company.id, {logoUrl: result.url}).then(() => {
            this.snackbar.openFromComponent(SnackBarComponent, {data: `New company added`});
          });
          this.loadItems();
        });
      });
    });
  }

  editCompany(company: CompanyInfo): void {
    const dialogRef = this.dialog.open(EditCompanyDialogComponent, {
      data: new EditCompanyDialogModel(company, undefined)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.teamCompaniesService.uploadLogo(company.id, dialogResult.imageData).then(result => {
        dialogResult.logoUrl = result.url;
        delete dialogResult.imageData;
        this.teamCompaniesService.modifyCompany(company.id, dialogResult).then(() => {
          this.snackbar.openFromComponent(SnackBarComponent, {data: `Company updated`});
          this.loadItems();
        });
      });
    });
  }

  setManagers(company: CompanyInfo): void {
    const dialogRef = this.dialog.open(EditCompanyManagersDialogComponent, {
      data: new EditCompanyManagersDialogModel(company)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
        this.loadItems();
    });
  }

  removeCompany(company: CompanyInfo): void {
    this.teamsService.getAllTeams().then(teams => {
      if (teams.find((team: { companyId: string; }) => team.companyId === company.id)) {
        this.snackbar.openFromComponent(SnackBarComponent, {data:
              `This company is associated with the following teams and cannot be deleted until the teams are no longer associated with it: ${teams.filter((team: { companyId: string; }) => team.companyId === company.id).map((team: { name: any; }) => team.name).join(', ')}`});
      } else {
        this.uiAlertService.presentAlertConfirm(`Do you really want to remove this company?`).then(confirm => {
          if (confirm) {
            this.teamCompaniesService.deleteCompany(company.id).then (_ => {
              this.snackbar.openFromComponent(SnackBarComponent, {data: `Company deleted`});
              this.loadItems();
            });
          }
        });
      }
    });
  }

  showJSON(value: any): string {
    return JSON.stringify(value);
  }

  parentName(company: CompanyInfo): string | undefined {
    return this.companies.find(thisCompany => thisCompany.id === company.parentId)?.name;
  }

  setCompanyParent(company: CompanyInfo): void {
    const dialogRef = this.dialog.open(SetTeamCompanyDialogComponent, {
      data: new SetTeamCompanyComponentModel('', 'Set Parent Company',
          '', 'Set Parent Company', 'Remove Parent Company', false)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult !== undefined) {
        if (dialogResult) {
          const visitedCompanies: CompanyInfo[] = [];
          visitedCompanies.push(company);
          let thisCompany: CompanyInfo | undefined = this.companies.find(newParentCompany => newParentCompany.id === dialogResult);
          while (thisCompany) {
            // @ts-ignore
            if (visitedCompanies.find(visitedCompany => visitedCompany.id === thisCompany.id)) {
              this.snackbar.openFromComponent(SnackBarComponent, {data: `The parent company you have chosen creates a circular reference. Companies must be arranged as a tree with no loops.`});
              return;
            }
            visitedCompanies.push(thisCompany);
            // @ts-ignore
            thisCompany = this.companies.find(itemCompany => itemCompany.id === thisCompany.parentId);
          }
        }
        this.teamCompaniesService.modifyCompany(company.id, {parentId: dialogResult}).then(_ => {
          this.loadItems();
          this.snackbar.openFromComponent(SnackBarComponent, {data: dialogResult ? `Parent company set for ${company.name}` : `Parent company removed for ${company.name}`});
        });
      }
    });

  }

  setTeamEmployer(item: Node): void {
    const dialogRef = this.dialog.open(SetTeamCompanyDialogComponent, {
      data: new SetTeamCompanyComponentModel('', 'Set Team Employer',
          'You can override the default association of teams to employers based on the team admin\'s email domain name.', 'Set Team Employer', 'Reset to Default', true)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult !== undefined) {
        this.teamsService.modifyTeam(item.id, {companyId: dialogResult}).then(_ => {
          this.snackbar.openFromComponent(SnackBarComponent, {data: `Employer set for ${item.name}`});
        });
      }
    });
  }

  viewTeam(item: Node): void {
    this.router.navigate(['managewellspace/overview', item.id]);
  }

  export(): void {
    this.exportToCsvService.export('All Companies', this.dataSource.data);
  }

}
