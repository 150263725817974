import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
    providedIn: 'root'
})
export class TeamCompanyUsersService {

    constructor(
        private restangular: Restangular,
    ) { }

    getCompanyUsers(companyId: string): Promise<any> {
        return this.restangular.one('teams').one('companyusers', companyId).getList().toPromise();
    }

    getCompanyUsersByUserId(userId: string): Promise<any> {
        return this.restangular.one('teams').one('companyusers').one('managers').one('me').getList().toPromise();
    }

    deleteCompanyUser(companyUserId: string): Promise<any> {
        return this.restangular.one('teams').one('companyusers', companyUserId).remove().toPromise();
    }

    modifyCompanyUser(companyUserId: string, fields: any): Promise<any> {
        return this.restangular.one('teams').one('companyusers', companyUserId).customPUT({
            ...fields
        }).toPromise();
    }

    createCompanyUser(companyId: string, userId: string, role: string): Promise<any> {
        return this.restangular.one('teams').one('companyusers').customPOST({
            companyId,
            userId,
            role
        }).toPromise();
    }

    inviteCompanyManager(companyName: string, email: string): Promise<any> {
        return this.restangular.one('teams').one('companyusers').one('invite').customPOST({
            companyName,
            email,
        }).toPromise();
    }

    getManagers(): Promise<any> {
        return this.restangular.one('teams').one('companies').one('managers').one('all').getList().toPromise();
    }

}
