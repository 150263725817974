import { Component } from '@angular/core';

@Component({
  selector: 'app-client-census',
  templateUrl: './client-census.component.html',
  styleUrls: ['./client-census.component.scss']
})
export class ClientCensusComponent {

  public table = {
    items: [],
    columnsToDisplay: [],
    menuItems: []
  };

}
