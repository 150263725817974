import { Component } from '@angular/core';

@Component({
  selector: 'app-agency-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent {

}
