import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss']
})
export class AgencyComponent implements OnInit {

  public links = [
    { title: 'Profile', link: '/brokeragency/agency/agency-profile'},
    { title: 'Members', link: '/brokeragency/agency/agency-members'},
    { title: 'Producers', link: '/brokeragency/agency/agency-producers'}
  ];

  public activeLink: any;

  constructor(
      private router: Router
  ) { }

  ngOnInit(): void {
    const parsedUrl = this.router.parseUrl(this.router.url);
    const urlSegments = parsedUrl.root.children.primary.segments;
    urlSegments.length = 3;
    this.activeLink = this.links.find(link => link.link === parsedUrl.toString());
  }

  gotoTab(link: string): void {
    this.router.navigate([link]);
    this.activeLink = link;
  }

}
