<div *ngIf="isModal">
    <h1 mat-dialog-title>
        {{company.id ? 'Edit' : 'Add New'}} Company
    </h1>
    <div mat-dialog-content>
        <ng-container *ngTemplateOutlet="formfields"></ng-container>
    </div>
    <div class="buttons" mat-dialog-actions>
        <button mat-raised-button [disabled]="!form.valid" class="donebutton" color="primary" (click)="okClicked()">OK</button>
        <button *ngIf="!mode" mat-button (click)="onDismiss()">Cancel</button>
    </div>
</div>

<div *ngIf="!isModal" class="notmodal">
    <ng-container *ngTemplateOutlet="formfields"></ng-container>
    <div class="buttons">
        <button mat-raised-button [disabled]="!form.valid" class="donebutton" color="primary" (click)="saveClicked()">Save</button>
    </div>
</div>

<ng-template #formfields>
    <div class="formparts">
        <form class="form" *ngIf="form" [formGroup]="form" padding>
            <mat-form-field>
                <mat-label>Company Name</mat-label>
                <input type="text" matInput placeholder="" formControlName="name">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Company Logo</mat-label>
                <app-imageupload [imageUrl]="form.get('logoUrl')?.value" (uploadedEvent)="imageUploaded($event)"></app-imageupload>
                <input matInput class="hidden" type="text">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Company Type</mat-label>
                <mat-select formControlName="type">
                    <mat-option value="wallit">Wallit</mat-option>
                    <mat-option value="carrier">Carrier</mat-option>
                    <mat-option value="broker">Broker</mat-option>
                    <mat-option value="agency">Agency</mat-option>
                    <mat-option value="employer">Employer/Client</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>SIC Code</mat-label>
                <input type="text" matInput placeholder="" formControlName="SIC">
            </mat-form-field>
            <mat-form-field>
                <mat-label>EIN</mat-label>
                <input type="text" matInput placeholder="" formControlName="EIN">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Address 1</mat-label>
                <input type="text" matInput placeholder="" formControlName="address1">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Address 2</mat-label>
                <input type="text" matInput placeholder="" formControlName="address2">
            </mat-form-field>
            <mat-form-field>
                <mat-label>City</mat-label>
                <input type="text" matInput placeholder="" formControlName="city">
            </mat-form-field>
            <mat-form-field>
                <mat-label>State</mat-label>
                <input type="text" matInput placeholder="" formControlName="state">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Zip Code</mat-label>
                <input type="text" matInput placeholder="" formControlName="postcode">
            </mat-form-field>
            <mat-form-field *ngIf="isModal">
                <mat-label>Email Domain Name</mat-label>
                <input type="text" matInput placeholder="" formControlName="domainName">
            </mat-form-field>
        </form>
        <form class="form" *ngIf="form && isModal" [formGroup]="form" padding>
            <mat-label>Configuration JSON</mat-label>
            <json-editor style="min-height: 400px;" [options]="editorOptions()" formControlName="payrollConfig"></json-editor>
        </form>
    </div>
</ng-template>
