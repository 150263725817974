<div class="topline">
    <h1 mat-dialog-title>
        {{supportUsers ? 'Add/Remove Support Agents' : 'Edit Roles'}}
    </h1>
    <button mat-fab (click)="playHelpMovie()"><mat-icon>movie</mat-icon></button>
</div>
<span class="info">You can only invite active users to {{supportUsers ? 'be a support agent' : 'roles'}}. Please invite new users by supplying their email address and waiting for them to sign up before adding them {{roleName}}.</span>
<div mat-dialog-content>
    <form class="form" *ngIf="form" [formGroup]="form" padding>
        <mat-form-field class="rightspacer">
            <mat-label>Email</mat-label>
            <input matInput placeholder="" formControlName="email">
        </mat-form-field>
        <mat-form-field *ngIf="!supportUsers" class="rightspacer">
            <mat-label>Role</mat-label>
            <mat-select formControlName="role">
                <mat-option value="manager">Team Manager</mat-option>
                <mat-option value="supportmanager">Support Manager</mat-option>
                <mat-divider></mat-divider>
                <mat-option value="broker">Broker</mat-option>
                <mat-option value="broker">Agency</mat-option>
                <mat-option value="broker">Client</mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button [disabled]="!form.valid" class="donebutton rightspacer" color="primary" (click)="foundUsers.length > 0 || selectedUser ? newMemberClicked() : inviteClicked()">{{foundUsers.length > 0 || selectedUser ? 'Add New ' + capitalizeFirstLetter(roleName) : 'Invite New User'}}</button>
        <mat-action-list *ngIf="foundUsers.length > 0">
            <button *ngFor="let user of foundUsers"mat-list-item (click)="userSelected(user)">{{user.firstName}} {{user.lastName}} ({{user.email}})</button>
        </mat-action-list>
    </form>
    <table mat-table matSort [dataSource]="managers">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">User Name</th>
            <td class="cell" mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Email</th>
            <td class="cell" mat-cell *matCellDef="let item">{{item.email}}</td>
        </ng-container>
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Role</th>
            <td class="cell" mat-cell *matCellDef="let item">{{item.role}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td class="cell" mat-cell *matCellDef="let item">
                <button (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="memberMenu" [matMenuTriggerData]="{item: item}"><mat-icon class="more" matTooltip="Actions">more_vert</mat-icon></button>
            </td>
        </ng-container>
        <mat-menu #memberMenu="matMenu">
            <ng-template matMenuContent let-item="item">
                <button mat-menu-item (click)="deleteMember(item)">Remove</button>
                <button *ngIf="!supportUsers" mat-menu-item (click)="loginManager(item)">Login as {{item.role | titlecase}}</button>
            </ng-template>
        </mat-menu>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
    </table>
    <span *ngIf="managers.data.length === 0">{{supportUsers ? 'No support agents added yet' : 'This company does not have any managers'}}</span>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button class="donebutton" color="primary" (click)="okClicked()">Done</button>
</div>
