export class AppSettings {

    public static isStaging: boolean = window.location.host.indexOf('staging') !== -1;
    public static isProd: boolean = (window.location.host.indexOf('my') !== -1) || window.location.host.indexOf('prod') !== -1;
    public static apiParam = new URLSearchParams(document.location.search).get('api');
    public static modeParam = new URLSearchParams(document.location.search).get('mode');
    private static INTEGRATION_DEV_MODE = false;
    public static SUPPORT_DEV_MODE = false; // window.location.host.indexOf('localhost') !== -1;
    public static BROKERADMIN_DEV_MODE = false;
    private static isIntegrationAdmin = AppSettings.INTEGRATION_DEV_MODE || window.location.host.indexOf('integrationadmin') !== -1;
    private static isCustomerSupportAdmin = AppSettings.SUPPORT_DEV_MODE || window.location.host.indexOf('customersupport') !== -1;
    private static isBrokerAdmin = AppSettings.BROKERADMIN_DEV_MODE || window.location.host.indexOf('brokeradmin') !== -1;
    private static isDemo: boolean = window.location.host.indexOf('demo') >= 0;
    private static isDev: boolean = window.location.host.indexOf('dev') >= 0;
    private static isQa: boolean = window.location.host.indexOf('qa') >= 0;
    public static isLocal: boolean = window.location.host.indexOf('local') >= 0;
    private static PRODUCTION_ENDPOINT = 'https://production.api.wallit.app/';
    private static STAGING_ENDPOINT = 'https://staging.api.wallit.app/';
    private static QA_ENDPOINT = 'https://qa.api.wallit.app/';
    private static DEMO_ENDPOINT = 'https://production.api.wallit.app/';
    private static DEV_ENDPOINT = 'https://dev.api.wallit.app/';
    private static LOCAL_ENDPOINT = 'http://localhost:8080/';
    private static PRODUCTION_ENDPOINT_INTEGRATION = 'https://integration-prod.api.wallit.app/v1';
    private static STAGING_ENDPOINT_INTEGRATION = 'https://integration-prod.api.wallit.app/v1';
    private static QA_ENDPOINT_INTEGRATION = 'https://integration-qa.api.wallit.app/v1';
    private static DEMO_ENDPOINT_INTEGRATION = 'https://integration-prod.api.wallit.app/v1';
    private static LOCAL_ENDPOINT_INTEGRATION = 'http://localhost:8080/v1';
    private static STRIPE_SANDBOX = {
        key: 'pk_test_51IG3Y1JQ7tkZop4Eyg8j9kMh65HLTFwXeMzJ9KEeI7HqLlvHuLCzFWrgwEFvBKrb2CLrKmETmlLpSs4b1yZ1QhAA00zSmmle7e'
    };
    private static STRIPE_PROD = {
        key: 'pk_live_51IG3Y1JQ7tkZop4Edab3EB5mTzj4hwshJinyAmgrbn3J0kJ7hbQlBRzuU5q6AZnTpsd9w96KEhMeVyJZZ5tbBDLT00OxYVZsEf'
    };
    private static PLAID_SANDBOX = {
        env: 'sandbox',
        key: '2abf2e2c85785d0f31bb220328d26b',
    };
    private static PLAID_PROD = {
        env: 'production',
        key: '2abf2e2c85785d0f31bb220328d26b'
    };
    private static PLAID_DEVELOPMENT = {
        env: 'development',
        key: '2abf2e2c85785d0f31bb220328d26b'
    };

    public static isIntegration(): boolean {
        return this.modeParam ? this.modeParam === 'integration' : this.isIntegrationAdmin;
    }

    public static isCustomerSupport(): boolean {
        return this.modeParam ? this.modeParam === 'support' : this.isCustomerSupportAdmin;
    }

    public static isBroker(): boolean {
        return this.modeParam ? this.modeParam === 'broker' : this.isBrokerAdmin;
    }

    public static getPlaidEnvironment(): any {
        switch (AppSettings.apiParam) {
            case 'local':
                return this.PLAID_SANDBOX;
            case 'demo':
                return this.PLAID_SANDBOX;
            case'qa':
                return this.PLAID_SANDBOX;
            case 'staging':
                return this.PLAID_PROD;
            case 'prod':
            case 'localprod':
                return this.PLAID_PROD;
            case 'dev':
                return this.PLAID_DEVELOPMENT;
            default:
                if (this.isDemo || this.isQa) {
                    return this.PLAID_SANDBOX;
                } else if (this.isDev || this.isLocal) {
                    return this.PLAID_DEVELOPMENT;
                } else {
                    return this.PLAID_PROD;
                }
        }
    }

    public static getBrandOverride(): string {
        const override = new URLSearchParams(document.location.search).get('brand');
        if (override) {
            return override;
        }
        return '';
    }

    public static getReleaseVersion(): string {
        switch (AppSettings.apiParam) {
            case 'local':
                return 'Local QA';
            case 'demo':
                return 'Local Demo';
            case 'dev':
                return 'Dev';
            case'qa':
                return 'QA';
            case 'staging':
                return 'Staging';
            case 'prod':
                return 'Production';
            case 'localprod':
                return 'Local Production';
            default:
                if (this.isDemo) {
                    return 'Demo';
                } else if (this.isQa) {
                    return 'QA';
                } else if (this.isStaging) {
                    return 'Staging';
                } else if (this.isProd) {
                    return '';
                } else if (this.isDev) {
                    return 'Dev';
                } else if (this.isLocal) {
                    return 'Local';
                } else {
                    return '';
                }
        }
    }

    public static setApmTitle(): string {
        if (this.isDemo) {
            return 'Demo';
        } else if (this.isQa) {
            return 'QA';
        } else if (this.isStaging) {
            return 'Staging';
        } else if (this.isProd) {
            return 'Prod';
        } else if (this.isDev) {
            return 'Dev';
        } else if (this.isLocal) {
            return 'Localhost';
        } else {
            return '';
        }
    }

    public static getEndpoint(): string {
        switch (AppSettings.apiParam) {
            case 'local':
            case 'localprod':
                return this.LOCAL_ENDPOINT;
            case 'demo':
                return this.DEMO_ENDPOINT;
            case 'dev':
                return this.DEV_ENDPOINT;
            case'qa':
                return this.QA_ENDPOINT;
            case 'staging':
                return this.STAGING_ENDPOINT;
            case 'prod':
                return this.PRODUCTION_ENDPOINT;
            default:
                if (this.isDemo) {
                    return this.DEMO_ENDPOINT;
                } else if (this.isQa) {
                    return this.QA_ENDPOINT;
                } else if (this.isStaging) {
                    return this.STAGING_ENDPOINT;
                } else if (this.isProd) {
                    return this.PRODUCTION_ENDPOINT;
                } else if (this.isDev) {
                    return this.DEV_ENDPOINT;
                } else if (this.isLocal) {
                    return this.LOCAL_ENDPOINT;
                } else {
                    return this.PRODUCTION_ENDPOINT;
                }
        }
    }

    public static getIntegrationEndpoint(): string {
        switch (AppSettings.apiParam) {
            case 'local':
            case 'localprod':
                return this.LOCAL_ENDPOINT_INTEGRATION;
            case 'demo':
                return this.DEMO_ENDPOINT_INTEGRATION;
            case'qa':
                return this.QA_ENDPOINT_INTEGRATION;
            case 'staging':
                return this.STAGING_ENDPOINT_INTEGRATION;
            case 'prod':
                return this.PRODUCTION_ENDPOINT_INTEGRATION;
            case 'dev':
                return this.QA_ENDPOINT_INTEGRATION;
            default:
                if (AppSettings.INTEGRATION_DEV_MODE) {
                    return this.LOCAL_ENDPOINT_INTEGRATION;
                }
                if (this.isDemo) {
                    return this.DEMO_ENDPOINT_INTEGRATION;
                } else if (this.isQa) {
                    return this.QA_ENDPOINT_INTEGRATION;
                } else if (this.isStaging) {
                    return this.STAGING_ENDPOINT_INTEGRATION;
                } else if (this.isProd) {
                    return this.PRODUCTION_ENDPOINT_INTEGRATION;
                } else if (this.isDev) {
                    return this.QA_ENDPOINT_INTEGRATION;
                } else if (this.isLocal) {
                    return this.LOCAL_ENDPOINT_INTEGRATION;
                } else {
                    return this.PRODUCTION_ENDPOINT_INTEGRATION;
                }
        }
    }

    public static getStripeInfo(): any {
        switch (AppSettings.apiParam) {
            case 'local':
                return this.STRIPE_SANDBOX;
            case 'demo':
                return this.STRIPE_SANDBOX;
            case'qa':
                return this.STRIPE_SANDBOX;
            case 'staging':
                return this.STRIPE_PROD;
            case 'prod':
            case 'localprod':
                return this.STRIPE_PROD;
            case 'dev':
                return this.STRIPE_SANDBOX;
            default:
                if (this.isDemo) {
                    return this.STRIPE_SANDBOX;
                } else if (this.isQa) {
                    return this.STRIPE_SANDBOX;
                } else if (this.isStaging) {
                    return this.STRIPE_PROD;
                } else if (this.isProd) {
                    return this.STRIPE_PROD;
                } else if (this.isDev) {
                    return this.STRIPE_SANDBOX;
                } else if (this.isLocal) {
                    return this.STRIPE_SANDBOX;
                } else {
                    return this.STRIPE_PROD;
                }
        }
    }
}
