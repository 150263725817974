import { Component } from '@angular/core';

@Component({
  selector: 'app-broker-agencies',
  templateUrl: './broker-agencies.component.html',
  styleUrls: ['./broker-agencies.component.scss']
})
export class BrokerAgenciesComponent {

}
