<mat-table [dataSource]="vendorProducts">
    <ng-container matColumnDef="vendorName">
        <th mat-header-cell *matHeaderCellDef>Vendor Name</th>
        <td mat-cell *matCellDef="let item">{{item.vendorName}}</td>
    </ng-container>
    <ng-container matColumnDef="vendorSlug">
        <th mat-header-cell *matHeaderCellDef>Vendor Tag</th>
        <td mat-cell *matCellDef="let item">{{item.vendorSlug}}</td>
    </ng-container>
    <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef>Product Name</th>
        <td mat-cell *matCellDef="let item">{{item.productName}}</td>
    </ng-container>
    <ng-container matColumnDef="productSlug">
        <th mat-header-cell *matHeaderCellDef>Product Tag</th>
        <td mat-cell *matCellDef="let item">{{item.productSlug}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</mat-table>
