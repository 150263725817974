<h1>Profile</h1>
<form class="form" [formGroup]="form">
    <div class="photo">
      <img #avatar class="icon" (click)="takePicture()">
      <input #camerainput type="file" style="display: none;" accept="image/*" (change)="changePhoto($event)">
      <div class="avatarbuttons">
          <button mat-raised-button [disabled]="uploading" color="accent" class="avatarbutton" (click)="clearAvatar()">Clear Avatar</button>
          <button mat-raised-button [disabled]="uploading" color="accent" class="avatarbutton" (click)="takePicture()">Change Avatar</button>
      </div>
    </div>
    <mat-form-field hintLabel="Max 50 characters">
        <mat-label>First Name</mat-label>
        <input matInput #input maxlength="10" placeholder="" formControlName="firstname">
        <mat-hint align="end">{{input.value.length || 0}}/50</mat-hint>
    </mat-form-field>
    <mat-form-field hintLabel="Max 50 characters">
        <mat-label>Last Name</mat-label>
        <input matInput #input2 maxlength="10" placeholder="" formControlName="lastname">
        <mat-hint align="end">{{input2.value.length || 0}}/50</mat-hint>
    </mat-form-field>
    <mat-form-field hintLabel="Max 50 characters">
        <mat-label>Nickname</mat-label>
        <input matInput #input3 maxlength="10" placeholder="" formControlName="nickname">
        <mat-hint align="end">{{input3.value.length || 0}}/50</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Date of Birth</mat-label>
      <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="dob">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Address 1</mat-label>
        <input matInput placeholder="" formControlName="address1">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Address 2</mat-label>
        <input matInput placeholder="" formControlName="address2">
    </mat-form-field>
    <mat-form-field>
        <mat-label>City</mat-label>
        <input matInput placeholder="" formControlName="city">
    </mat-form-field>
    <mat-form-field>
        <mat-label>State</mat-label>
        <input matInput placeholder="" formControlName="state">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Zip Code</mat-label>
        <input matInput placeholder="" formControlName="postalCode">
    </mat-form-field>
    <mat-form-field>
        <mat-label>SSN</mat-label>
        <div class="ssn-field">
            <input matInput placeholder="*** ** ****" formControlName="ssn" [type]="hideSsn ? 'password' : 'text'">
            <mat-icon class="hide-password-icon" [svgIcon]="hideSsn ? 'icon-eye-lock' : 'icon-eye'" (click)="hideSsn = !hideSsn"></mat-icon>
        </div>
    </mat-form-field>

    <mat-form-field hintLabel="me@example.com">
        <mat-label>Email</mat-label>
        <input matInput #input3  placeholder="" formControlName="email">
    </mat-form-field>
</form>
<button mat-raised-button color="primary" class="primarybutton" shape="" type="submit" [disabled]="!form.valid || !form.dirty" (click)="saveClicked($event)">Save</button>
