import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UsersService} from '../../../services/users/users.service';
import {TeamCompanyUsersService} from '../../../services/teamcompanyusers/teamcompanyusers.service';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';

@Component({
  selector: 'app-broker',
  templateUrl: './broker.component.html',
  styleUrls: ['./broker.component.scss']
})
export class BrokerComponent implements OnInit {

  public allLinks = [
    { title: 'Profile', link: '/brokeragency/broker/broker-profile'},
    { title: 'Agencies', link: '/brokeragency/broker/broker-agencies'},
    { title: 'Products', link: '/brokeragency/broker/broker-products'},
  ];

  public links = this.allLinks;

  public activeLink: any;
  public isSuperAdmin = false;
  public selectedBrokerId = '';
  public brokers: any;

  constructor(
      private router: Router,
      private usersService: UsersService,
      private teamCompaniesService: TeamCompaniesService
  ) {
  }

  ngOnInit(): void {
    const parsedUrl = this.router.parseUrl(this.router.url);
    const urlSegments = parsedUrl.root.children.primary.segments;
    urlSegments.length = 3;
    this.usersService.meSubscribe((me: any) => {
      if (me) {
        this.isSuperAdmin = this.usersService.isAdmin();
        this.links = this.isSuperAdmin ?  this.allLinks : this.allLinks.slice(0, -1);
      }
    });
    this.activeLink = this.links.find(link => link.link === parsedUrl.toString());
    this.teamCompaniesService.getCompanies().then((companies: any) => {
      this.brokers = companies.filter((company: { type: string; }) => company.type === 'broker');
    });
  }

  gotoTab(link: string): void {
    this.router.navigate([link]);
    this.activeLink = link;
  }

  setBroker(event: any): void {
    this.selectedBrokerId = event.value;
  }

}
