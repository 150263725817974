import { Component } from '@angular/core';

@Component({
  selector: 'app-agency-profile',
  templateUrl: './agency-profile.component.html',
  styleUrls: ['./agency-profile.component.scss']
})
export class AgencyProfileComponent {

  saveAgencyProfile(): void {

  }

}
