import { Component } from '@angular/core';

@Component({
  selector: 'app-client-enrollment',
  templateUrl: './client-enrollment.component.html',
  styleUrls: ['./client-enrollment.component.scss']
})
export class ClientEnrollmentComponent {

}
