<form [formGroup]="form" class="form">
    <mat-form-field *ngIf="items && items.length > 1">
        <mat-label>Select your payment method</mat-label>
        <mat-select formControlName="method">
            <mat-option *ngFor="let item of items" [value]="item">{{item.bankInfo?.bankName || '---'}} ****{{item.stripeLast4}}</mat-option>
        </mat-select>
    </mat-form-field>
</form>
<span class="mandatetext" *ngIf="invoices && invoices.length === 0">No charges have been made yet to this payment method.</span>
<span class="mandatetext" *ngIf="!invoices">Please select a payment method.</span>
<div *ngIf="invoices && invoices.length > 0">
    <table class="table">
        <tr lines="none" [class]="invoice.expanded ? 'expanded' : ''" *ngFor="let invoice of invoices">
            <!--<div class="itemwrapper">
                <div class="itemtop">-->
                    <td class="chargedate"><mat-icon class="expandcollapse" (click)="expandCollapse(invoice)">{{invoice.expanded ? 'arrow_drop_down' : 'arrow_right'}}</mat-icon>{{invoice.date}}</td>
                    <td text-wrap class="chargedescription" (click)="openInvoice(invoice.url)">{{invoice.info}}</td>
                    <td class="chargeamount">{{(invoice.amount) | currency}}</td>
                    <td class="chargedescription">
                        {{invoice.status}}
                    </td>
                <!--</div>-->
                <div class="detailswrapper" *ngIf="invoice.expanded">
                    <span>{{invoice.details.paymentType}}</span>
                    <div class="details-container">
                        <div class="details" *ngFor="let line of invoice?.details?.fee">
                            <div class="description">{{ line.description }}</div>
                            <div class="amount">{{ line?.amount | currency }}</div>
                            <div class="diff">{{ line?.diff | currency }}</div>
                        </div>
                    </div>
                </div>
            <!--</div>-->
        </tr>
    </table>
</div>
