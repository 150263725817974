import {Component, OnInit} from '@angular/core';
import {VendorProductsService} from '../../../services/vendorproducts/vendorproducts.service';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-broker-products',
  templateUrl: './broker-products.component.html',
  styleUrls: ['./broker-products.component.scss']
})
export class BrokerProductsComponent implements OnInit {

  public columnsToDisplay = ['vendorName', 'vendorSlug', 'productName', 'productSlug'];
  public vendorProducts = new MatTableDataSource<any>();

  constructor(
    private vendorProductsService: VendorProductsService
  ){
  }

  ngOnInit(): void {
    this.vendorProductsService.getAllVendorProducts().then(result => {
      this.vendorProducts.data = result;
    });
  }

}
