import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
    providedIn: 'root'
})
export class UsersInsuranceService {

    constructor(
        private restangular: Restangular,
    ) { }

    // PRIMARY

    getInsurancePrimary(userId: string): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).get().toPromise();
    }

    createInsurancePrimary(userId: string, gender: string, metadata: any, tobaccoUseDate: string): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).customPOST({
            gender,
            metadata,
            tobaccoUseDate,
        }).toPromise();
    }

    modifyInsurancePrimary(userId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).customPUT({
            ...fields
        }).toPromise();
    }

    deleteInsurancePrimary(userId: string): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).remove().toPromise();
    }

    // DEPENDENTS

    getInsuranceDependents(userId: string): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).getList().toPromise();
    }

    createInsuranceDependent(userId: string, gender: string, metadata: any, tobaccoUseDate: string, firstName: string, lastName: string, dob: string, relationship: string): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).customPOST({
            gender,
            metadata,
            tobaccoUseDate,
            firstName,
            lastName,
            dob,
            relationship,
        }).toPromise();
    }

    modifyInsuranceDependent(userId: string, dependentId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).one(dependentId).customPUT({
            ...fields
        }).toPromise();
    }

    deleteInsuranceDependent(userId: string, dependentId: string): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).one(dependentId).remove().toPromise();
    }

    // BENEFICIARIES

    getInsuranceBeneficiaries(userId: string): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).getList().toPromise();
    }

    createInsuranceBeneficiary(userId: string, beneficiaryType: string, firstName: string, lastName: string, ssn: string, relationship: string): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).customPOST({
            beneficiaryType,
            firstName,
            lastName,
            ssn,
            relationship,
        }).toPromise();
    }

    modifyInsuranceBeneficiary(userId: string, beneficiaryId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).one(beneficiaryId).customPUT({
            ...fields
        }).toPromise();
    }

    deleteInsuranceBeneficiary(userId: string, beneficiaryId: string): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).one(beneficiaryId).remove().toPromise();
    }

}
