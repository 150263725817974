import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-member-manager',
  templateUrl: './member-manager.component.html',
  styleUrls: ['./member-manager.component.scss']
})
export class MemberManagerComponent {

  @Input() table: any;

  export(): void {

  }

  import(): void {

  }

  addItem(): void {

  }

}
