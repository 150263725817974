import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {TeamsService} from '../../services/teams/teams.service';
import {MatSort} from '@angular/material/sort';
import {SessionService} from '../../services/session/session.service';
import {StripeService} from '../../services/stripe/stripe.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {ApiService} from '../../services/api/api.service';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {SnackBarComponent} from '../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SetTeamCompanyComponentModel, SetTeamCompanyDialogComponent} from '../modal/setteamcompanydialog/setteamcompanydialog.component';
import {TeamCompaniesService} from '../../services/teamcompanies/teamcompanies.service';
import {SetTeamPrivacyComponentModel, SetTeamPrivacyDialogComponent} from '../modal/setteamprivacydialog/setteamprivacydialog.component';
import {SendMode} from '../modal/sendfundsdialog/send-funds-dialog.page';
import {Router} from '@angular/router';
import {SetInvoicingComponentModel, SetInvoicingDialogComponent} from '../modal/setinvoicingdialog/setinvoicingdialog.component';
import {ClientService} from '../../services/client/client.service';
import {CustomerLinkModal, CustomerLinkModalComponent} from '../modal/customer-link-modal/customer-link-modal.component';

interface TeamInfo {
  name: string;
  ownerName: string;
  ownerUserId: string;
}

@Component({
  selector: 'app-teampayments',
  templateUrl: './teampayments.component.html',
  styleUrls: ['./teampayments.component.scss']
})
export class TeampaymentsComponent implements OnInit {

  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public items = new MatTableDataSource<TeamInfo>();
  public columnsToDisplay = ['name', 'client', 'company', 'ownerName', 'createdAt', 'approved', 'isInvoicePlan', 'actionmenu'];
  public filter = '';
  private currentWellspace: any;
  private companies = [];

  constructor(
      private teamsService: TeamsService,
      private sessionService: SessionService,
      private snackBar: MatSnackBar,
      private dialog: MatDialog,
      private stripeService: StripeService,
      private spinnerService: SpinnerService,
      private apiService: ApiService,
      private uiAlertService: UiAlertService,
      private teamCompaniesService: TeamCompaniesService,
      private router: Router,
      private clientService: ClientService
  ) {
  }

  ngOnInit(): void {
    this.getAllTeams();
    this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
      this.currentWellspace = wellspace;
    });
  }

  getAllTeams(): void {
    this.spinnerService.show();
    this.teamsService.getAllTeams().then(teams => {
      this.teamCompaniesService.getCompanies().then(companies => {
        this.companies = companies;
        this.items.data = teams;
        this.items.sort = this.sort;
        this.loadCompanyNames();
        this.spinnerService.hide();
      }).catch(err => {
        this.items.data = [];
        console.error('Error in get companies', err);
        this.snackBar.openFromComponent(SnackBarComponent, {data: err.error && err.error.message ? err.error.message : 'An error has occurred.'});
        this.spinnerService.hide();
      });
    }).catch(err => {
      this.items.data = [];
      console.error('Error in get all teams', err);
      this.snackBar.openFromComponent(SnackBarComponent, {data: err.error && err.error.message ? err.error.message : 'An error has occurred.'});
      this.spinnerService.hide();
    });
  }

  private loadCompanyNames(): void {
    console.log('TEAMS', this.items.data);
    console.log('COMPANIES', this.companies);
    this.items.data.forEach((team: any) => {
      // @ts-ignore
      team.company = this.companies.find((company: { id: any; }) => company.domainName?.toLowerCase() === team.ownerEmail?.split('@')[1].toLowerCase())?.name;
    });
  }

  clearFilter(): void {
    this.items.filter = '';
    this.filter = '';
  }

  applyFilter(event: any): void {
    let value = event.target.value;
    value = value.trim(); // Remove whitespace
    value = value.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.items.filter = value;
  }

  loginTeamAdmin(user: any): void {
    this.sessionService.loginByUserId(user.ownerUserId);
  }

  loginTeamMember(user: any): void {
    this.uiAlertService.memberLogin(user);
  }

  isSuperAdmin(): boolean {
    return this.sessionService.isSuperAdmin();
  }

  reconciliation(team: any): void {
    this.router.navigate(['reconciliation/stripe', team.id]);
  }

  triggerBilling(team: any): void {
    this.teamsService.getCustomerId(team).then(customerId => {
      if (customerId) {
        this.stripeService.triggerBillingCycle(customerId).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'Billing cycle triggered'});
        });
      } else {
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'No billing method configured'});
      }
    });
  }

  transferToWallit(team: any): void {
    this.uiAlertService.superAdminTransfer(team.id, SendMode.TO_WALLIT_BALANCE);
  }

  transferToAllowance(team: any): void {
    this.uiAlertService.superAdminTransfer(team.id, SendMode.TO_ALLOWANCE_BALANCE);
  }

  transferAllowanceToWallit(team: any): void {
    this.uiAlertService.superAdminTransfer(team.id, SendMode.ALLOWANCE_TO_WALLIT);
  }

  approveTeam(team: any): void {
    this.spinnerService.show();
    this.teamsService.modifyTeam(team.id, {approved: 1}).then(result => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: `Team ${team.name} has been approved`});
      team.approved = 1;
      this.checkCurrentWellspace(team);
      this.getAllTeams();
    });
  }

  checkCurrentWellspace(wellspace: any): void {
    this.teamsService.wellspaceChangedObservable.subscribe((res: any) => {
      if (res.id === wellspace.id) {
        this.teamsService.setWellspace(wellspace);
      }
    });
  }

  private reloadItems(): void {
    this.loadCompanyNames();
    this.items.data = this.items.data.slice();
  }

  setCompany(team: any): void {
    const dialogRef = this.dialog.open(SetTeamCompanyDialogComponent, {
      data: new SetTeamCompanyComponentModel(team.companyId, 'Set Team Company',
          'This team can be associated with a single company.', 'Set Company for Team', 'Remove Team Company', false)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult !== undefined) {
        this.teamsService.modifyTeam(team.id, {companyId: dialogResult});
        team.companyId = dialogResult;
        this.reloadItems();
        this.snackBar.openFromComponent(SnackBarComponent, {data: dialogResult ? `Company set for team ${team.name}` : `Company removed for team ${team.name}`});
      }
    });
  }

  setTransactionPrivacy(team: any): void {
    const dialogRef = this.dialog.open(SetTeamPrivacyDialogComponent, {
      data: new SetTeamPrivacyComponentModel(team.transactionPrivacy)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult !== undefined) {
        this.teamsService.modifyTeam(team.id, {transactionPrivacy: dialogResult});
        team.transactionPrivacy = dialogResult;
        this.reloadItems();
        this.snackBar.openFromComponent(SnackBarComponent, {data: dialogResult ? `Transaction privacy enabled for team ${team.name}` : `Transaction privacy disabled for team ${team.name}`});
      }
    });
  }

  setInvoicing(team: any): void {
      this.dialog.open(SetInvoicingDialogComponent, {
        data: new SetInvoicingComponentModel(team)
      });
  }

  async checkIfTeamHasSomeAchPending(team: any): Promise<boolean> {
    this.spinnerService.show('Loading...');
    const pendingCharge: any = await this.teamsService.checkTeamPendingCharges(team.id);
    return !!(pendingCharge && pendingCharge.hasPendingCharges);
  }

  showPendingAchSnack(dataMessage: string): void {
    this.spinnerService.hide();
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: dataMessage
    });
  }

  async deleteWellspace(team: any): Promise<void> {
    this.uiAlertService.presentAlertConfirm(`Do you really want to remove this wellspace?`).then(async confirm => {
      if (confirm) {
        const hasAchPending = await this.checkIfTeamHasSomeAchPending(team);
        if (hasAchPending) {
          return this.showPendingAchSnack(
              'A transfer is in progress at the moment. To ensure the integrity of the operation, ' +
              'it is not possible to make further changes at this time. Please wait until the transfer is completed to proceed with your actions.'
          );
        }
        this.teamsService.deleteTeam(team.id).then((): void => {
          this.showPendingAchSnack(`Team ${team.name} removed`);
        });
      }
    });
  }

  teamDetails(team: any): void {
    this.router.navigate(['managewellspace/overview', team.id]);
  }

  openCustomerLinkModal(team: any): void {
    const dialogRef = this.dialog.open(CustomerLinkModalComponent, {data: new CustomerLinkModal(team, this.items.data)});
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.getAllTeams();
      }
    });
  }

  createAnInvoice(team: any): void {
    const isInvoicePlan: boolean = team.isInvoicePlan === 0;
    if (team.id && team.id !== '') {
      this.uiAlertService.presentAlertConfirm(`Are you sure you want to create an invoice of team ${team.name} to client ${team?.client?.name ?? ''}?`).then(confirm => {
        if (confirm) {
          this.clientService.createInvoice(team.id, isInvoicePlan).then((): void => {
            this.getAllTeams();
          }).catch(err => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: err.error && err.error.message ? err.error.message : 'An error has occurred.'});
            console.error('Error in create an invoice', err);
          });
        }
      });
    } else {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Team not found.'});
    }
  }

  checkClientFeaturePermission(): boolean {
    return this.clientService.checkUserFeaturePermission();
  }

}
