import { Component } from '@angular/core';

@Component({
  selector: 'app-client-eligibility',
  templateUrl: './client-eligibility.component.html',
  styleUrls: ['./client-eligibility.component.scss']
})
export class ClientEligibilityComponent {

}
