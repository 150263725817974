import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {UsersService} from '../../services/users/users.service';
import {DomSanitizer} from '@angular/platform-browser';
import * as moment from 'moment/moment';
import {SessionService} from '../../services/session/session.service';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../snackbar/snackbar.component';
import { Router } from '@angular/router';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.page.html',
  styleUrls: ['./edit-profile.page.scss'],
})
export class ProfileComponent implements OnInit, AfterViewInit {

  public form;

  private oldTel = '';

  public qrcodeJson = {};
  public maxDate = moment().add(-18, 'year').toDate();
  private birthdate = '';
  public isVerified = false;
  public uploading = false;

  @ViewChild('camerainput') cameraInput?: ElementRef;
  @ViewChild('avatar') avatar?: ElementRef;

  currentEmail?: string;

  hideSsn = true;

  constructor(
      private formBuilder: UntypedFormBuilder,
      private usersService: UsersService,
      private domSanitizer: DomSanitizer,
      private snackBar: MatSnackBar,
      private sessionService: SessionService,
      private uiAlertService: UiAlertService,
      public router: Router
  ) {
    this.form = this.formBuilder.group({
      firstname: new UntypedFormControl('', Validators.required),
      lastname: new UntypedFormControl('', Validators.required),
      nickname: new UntypedFormControl(''),
      dob: new UntypedFormControl(''),
      tel: new UntypedFormControl(''),
      email: new UntypedFormControl('', Validators.email),
      address1: new UntypedFormControl(''),
      address2: new UntypedFormControl(''),
      city: new UntypedFormControl(''),
      state: new UntypedFormControl(''),
      postalCode: new UntypedFormControl(''),
      ssn: new UntypedFormControl(''),
    });
    this.qrcodeJson = { appId: 'wallit.app', userId: this.usersService.getCurrentUserId()};
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.usersService.meSubscribe((response: any) => {
      if (response) {
        this.oldTel = this.getPhoneNumber(response);
        this.form.get('firstname')?.setValue(response.user.firstName);
        this.form.get('lastname')?.setValue(response.user.lastName);
        this.form.get('nickname')?.setValue(response.user.nickname);
        this.form.get('dob')?.setValue(response.user.birthdate);
        this.form.get('tel')?.setValue(this.oldTel);
        this.form.get('email')?.setValue(response.user.email);
        this.form.get('address1')?.setValue(response.user.address1);
        this.form.get('address2')?.setValue(response.user.address2);
        this.form.get('city')?.setValue(response.user.city);
        this.form.get('state')?.setValue(response.user.state);
        this.form.get('postalCode')?.setValue(response.user.postalCode);
        this.form.get('ssn')?.setValue(response.user.ssn);
        this.isVerified = response.user.isVerified;
        this.usersService.getAvatar(this.usersService.me().user, this.avatar?.nativeElement);
        this.form.markAsPristine();
        this.currentEmail = this.form.get('email')?.value;
      }
    });
  }

  private getPhoneNumber(response: any): string {
    let tel = response.phoneNumber || (response.phoneNumbers && response.phoneNumbers.length > 0 ?
        response.phoneNumbers[0].phoneNumber : '');
    if (tel && tel[0] === '1') {
      tel = tel.substr(1);
    }
    return tel;
  }

  saveClicked(event: any): void {
    event.preventDefault();
    const data = {
      firstName: this.form.get('firstname')?.value,
      lastName: this.form.get('lastname')?.value,
      nickname: this.form.get('nickname')?.value,
      birthdate: this.form.get('dob')?.value,
      email: this.form.get('email')?.value,
      address1: this.form.get('address1')?.value,
      address2: this.form.get('address2')?.value,
      city: this.form.get('city')?.value,
      state: this.form.get('state')?.value,
      postalCode: this.form.get('postalCode')?.value,
      ssn: this.form.get('ssn')?.value,
    };
    const done = () => {
      this.usersService.reloadMe(true);
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Profile saved'});
    };
    this.usersService.updateUser(this.usersService.getCurrentUserId(), data).then((response: any) => {
      const newTel = this.form.get('tel')?.value;
      if (this.oldTel !== newTel) {
        this.sendPhoneChangeSMS(newTel);
      }
      if (this.currentEmail !== this.form.get('email')?.value) {
        this.router.navigate(['emailcode', this.currentEmail], {queryParams: {changedEmail: this.form.get('email')?.value} });
      }
      done();
     }).catch((error: any) => {
      this.snackBar.openFromComponent(SnackBarComponent, error.error);
      done();
    });
  }

  private sendPhoneChangeSMS(phone: string): void {
    this.usersService.setPhoneNumber(phone).then(result => {
      if (this.oldTel) {
        this.sessionService.deletePhoneNumber(this.oldTel).then((response: any) => {
        }).catch((error: any) => {
          this.snackBar.openFromComponent(SnackBarComponent, error.error);
        });
      }
      this.uiAlertService.enterPIN(phone).then((pin: string | undefined) => {
        if (pin) {
          this.sessionService.loginValidateCode(phone, pin).then(() => {
          }).catch((error: any) => {
            this.usersService.removePhoneNumber(phone);
            this.snackBar.openFromComponent(SnackBarComponent, {data: 'Invalid SMS code'});
          });
        }
      }).catch((error: any) => {
        this.usersService.removePhoneNumber(phone);
      });
    }).catch((error) => {
      this.snackBar.openFromComponent(SnackBarComponent, error.error);
    });
  }

  private base64ToArrayBuffer(base64: any): ArrayBuffer {
    const binaryString =  window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array( len );
    for (let i = 0; i < len; i++)        {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  takePicture(): void {
      this.cameraInput?.nativeElement.click();
  }

  changePhoto($event: any): void {
    if ($event && $event.target.files[0] && $event.target.files[0].type.indexOf('image') < 0) {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'You did not select a valid image file'});
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      this.uploadImage(reader.result);
    };
    reader.readAsArrayBuffer($event.target.files[0]);
  }

  private uploadImage(img: any): void {
    this.uploading = true;
    this.usersService.setAvatar(img).then((response: any) => {
      this.usersService.reloadMe(true);
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'New avatar loaded'});
      this.uploading = false;
    }).catch((error: any) => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Image size too large'});
      this.uploading = false;
    });
  }

  clearAvatar(): void {
    this.uploading = true;
    this.usersService.removeAvatar().then(() => {
      this.usersService.reloadMe(true);
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Avatar removed'});
      this.uploading = false;
    });
  }

  verifyPhoneNumber(): void {

  }

}

