import { Component } from '@angular/core';

@Component({
  selector: 'app-agency-producers',
  templateUrl: './producers.component.html',
  styleUrls: ['./producers.component.scss']
})
export class ProducersComponent {

}
