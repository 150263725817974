import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Restangular} from 'ngx-restangular';
import {ApiService} from '../api/api.service';
// @ts-ignore
import Avatar from 'avatar-initials';
import {AppSettings} from '../../app.settings';
import {SessionService} from '../session/session.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  ME_UPDATE_TIME = 100;

  private reload = true;
  private reloadAvatar = false;
  private meObservable: BehaviorSubject<any>;
  private isIntegrationAdmin = AppSettings.isIntegration();

  constructor(
      private apiService: ApiService,
      protected restangular: Restangular,
      private sessionService: SessionService,
      private router: Router
  ) {
    this.meObservable = new BehaviorSubject(null);
    if (!this.isIntegrationAdmin) {
      setInterval(() => {
        if (this.reload && apiService.isAuthenticated()) {
          this.reload = false;
          this.restangular.one('me').get().toPromise().then((data: any) => {
            if (data.user.isActivatedUser === 0) {
              this.sessionService.logout();
              this.router.navigate(['login']);
            }
            if (this.reloadAvatar) {
              data.user.avatar = data.user.avatar + '?rand=' + Math.random().toString(36).substring(7);
            }
            this.meObservable.next(data);
          }).catch(() => {
            this.reload = true;
          });
        }
      }, this.ME_UPDATE_TIME);
    }
  }

  me(): any {
    return this.meObservable.getValue();
  }

  // isChild(id?: string): boolean {
  //   if (id) {
  //     return this.lookupFamilyMember(id).permissionLevel === 1;
  //   }
  //   const me = this.me();
  //   return (me && me.user) ? me.user.permissionLevel === 1 : false;
  // }
  //
  // isParent(): boolean {
  //   const me = this.me();
  //   return (me && me.user) ? me.user.permissionLevel === 8 : false;
  // }

  isAdmin(): boolean {
    const me = this.me();
    return (me && me.user) ? me.user.permissionLevel > 8 : false;
  }

  // isCoparent(): boolean {
  //   const me = this.me();
  //   if (!me && !me.user && !me.family) {
  //     return false;
  //   }
  //   return me.user.permissionLevel === 8 && me.family.ownerId !== me.user.id;
  // }

  getCurrentUserId(): string {
    return this.me() ? this.me().user.id : '';
  }

  // getCurrentFamilyId(): string {
  //   return this.me() ? this.me().family.id : '';
  // }

  getCurrentEmail(): string {
    return this.me() ? this.me().user.email : '';
  }

  // isMemberCoparent(member: any): boolean {
  //   const me = this.me();
  //   return member.userType === 'parent' && member.userId !== me.family.ownerId;
  // }
  //
  // public getFamilyOwnerId(): string {
  //   return this.me().family.ownerId;
  // }
  //
  // public clearAndReloadMe(): void {
  //   this.meObservable.next(undefined);
  //   this.reloadMe();
  // }

  public reloadMe(reloadAvatar = false): void {
    this.reload = true;
    this.reloadAvatar = true;
  }

  public meSubscribe(response: any): void {
    this.meObservable.subscribe(response);
    this.meObservable.next(this.meObservable.getValue());
  }

  // isOnboardingCompleted(): any {
  //   return this.me().family;
  // }

  public lookupFamilyMember(id: string): any {
    const me = this.me();
    if (me && me.user.id === id) {
      return me.user;
    }
    return me ? me.usersFamily.find((member: any) => member.userId === id) : '';
  }

  public setPhoneNumber(phoneNumber: string, familyId = '', userId = ''): Promise<any> {
    const me = this.me();
    if (familyId !== '') {
      return this.restangular.one('families', familyId).one('users', userId).one('phoneNumbers', phoneNumber).customPUT().toPromise();
    } else {
      return this.restangular.one('users', me.user.id).one('phoneNumbers', phoneNumber).customPUT().toPromise();
    }
  }

  public removePhoneNumber(phoneNumber: string): Promise<any> {
    const me = this.me();
    return this.restangular.one('users', me.user.id).one('phoneNumbers', phoneNumber).remove().toPromise();
  }

  public setAvatar(image: any): Promise<any> {
    const me = this.me();
    return this.restangular.one('users', me.user.id).all('avatar').
    customPUT(image, undefined, undefined, { 'content-type': 'image/*' }).toPromise();
  }

  public setPassword(userId: string, password: string): Promise<any> {
    return this.restangular.one('users', userId).one('password').customPUT({password, passwordConfirmation: password}).toPromise();
  }

  public removeAvatar(): Promise<any> {
    const me = this.me();
    return this.restangular.one('users', me.user.id).one('avatar').remove().toPromise();
  }

  public getUserById(userId: string): Promise<any> {
    return this.restangular.one('users', userId).get().toPromise();
  }

  // public setNotificationSettings(email: boolean, sms: boolean, offers: boolean): Promise<any> {
  //   const data = {
  //     emailSetting: +email,
  //     smsSetting: +sms,
  //     offersSetting: +offers
  //   };
  //   const me = this.me();
  //   return this.restangular.one('users', me.user.id).all('notifications-settings').customPUT(data).toPromise();
  // }

  public updateUser(userId: string, data: any): Promise<any> {
    const me = this.me();
    return this.restangular.one('users', userId).customPATCH(data).toPromise();
  }

  // public requestPasswordReset(userOrEmail: string, phone: string): Promise<any> {
  //   return this.restangular.one('public').one('users').one('reset-password').
  //   customPOST({ emailOrUsername: userOrEmail, phone }).toPromise();
  // }
  //
  // public resetPassword(resetCode: string, password: string, passwordConfirmation: string): Promise<any> {
  //   return this.restangular.one('auth').one('user-password').one('resetPassword', resetCode).
  //   customPUT({ password, passwordConfirmation}).toPromise();
  // }
  //
  // public recoverUsername(email: string, phone: string): Promise<any> {
  //   return this.restangular.one('public').one('users').one('nickname-recovery').customPOST({ email, phone }).toPromise();
  // }

  public queryUsers(query: string): Promise<any> {
    return this.restangular.one(`users?filter=${encodeURIComponent(query)}`).getList().toPromise();
  }

  public getUsers(filter: string, isAdminFilter: boolean, selectedBrandFilter: string, page: number, limit: number): Promise<any> {
    return this.restangular.one(`users?filter=${encodeURIComponent(filter)}&isAdmin=${isAdminFilter || ''}&partnerId=${selectedBrandFilter}&page=${page}&limit=${limit}`).getList().toPromise();
  }

  // public queryUserById(userId: string): Promise<any> {
  //   return this.restangular.one('users', userId).get().toPromise();
  // }
  //
  // public findFriends(familyId: string, userId: string, contacts: any): Promise<any> {
  //   return this.restangular.one('families', familyId).one('users', userId).one('find').customPOST(contacts).toPromise();
  // }
  //
  // public getRecentUsers(userId: string): Promise<any> {
  //   return this.restangular.one('users', userId).one('recentusers').getList()().toPromise();
  // }
  //
  // getAvatarOld(user: any): string {
  //   if (user.avatar && user.avatar.includes('no_avatar.png')) {
  //     return 'https://ui-avatars.com/api/?size=128&name=' + user.firstName + '%20' + user.lastName;
  //   } else {
  //     return user.avatar;
  //   }
  // }

  getAvatar(user: any, domElement: any): boolean {
    if (user.avatar && user.avatar.includes('no_avatar.png')) {
      const avatar = new Avatar(domElement, {
        useGravatar: false,
        initials: user.firstName ? (user.firstName?.charAt(0) + user.lastName?.charAt(0)) : user.email?.charAt(0),
      });
      return true;
    } else {
      domElement.src = user.avatar;
      return user.avatar;
    }
  }

  validatePhoneCode(userId: string, phoneNumber: string, verificationCode: string): Promise<any> {
    return this.restangular.one('users', userId).one('phoneNumbers', phoneNumber).one('codeVerification', verificationCode).customPUT().toPromise();
  }

  validateEmailCode(userId: string, code: string): Promise<any> {
    return this.restangular.one('users', userId).one('validatemail', code).customPUT().toPromise();
  }

  // isEmailValidated(userId: string): Promise<any> {
  //   return this.restangular.one('users', userId).one('isemailvalidated').get().toPromise();
  // }

  resendPhoneValidation(userId: string, phoneNumber: string, oldPhone?: string): Promise<any> {
    return this.setPhoneNumber(phoneNumber).then(result => {
      if (oldPhone) {
        return this.sessionService.deletePhoneNumber(oldPhone);
      } else {
        return {};
      }
    });
  }

  confirmPhoneNumber(userId: string, phoneNumber: string): Promise<any> {
    return this.restangular.one('users', userId).one('phoneNumbers', phoneNumber).one('confirm').customPUT().toPromise();
  }

  setPhoneNumberByUserId(userId: string, phoneNumber: string): Promise<any> {
    return this.restangular.one('users', userId).one('phoneNumbers', phoneNumber).customPUT().toPromise();
  }

  resendEmailValidation(userId?: string, email?: string): Promise<any> {
    return this.restangular.one('users', userId || this.getCurrentUserId()).one('generatevalidationemail').customPUT({email}).toPromise();
  }

  login(userId: string): Promise<any> {
    return this.restangular.one('admin/userLogin', userId).post().toPromise();
  }

  loginUserV3(userId: string, devMode: boolean = false, func: ((arg: string) => void) | null): void {
    this.login(userId).then(data => {
        let token = data.v3Url.match(/\/token\/.*/);
        if (func) {
          token += '/true'; // puts app in suport mode
        }
        const url = devMode ? `http://localhost:4000${token}?api=localprod` : data.v3Url;
        if (func) {
          // @ts-ignore
          func(url);
        } else {
          window.open(url, '_blank');
        }
    });
  }

  getCompanies(partnerId: string): Promise<any> {
    return this.restangular.one('users', this.getCurrentUserId()).one('companies', partnerId).getList().toPromise();
  }

  getBrands(): Promise<any> {
    return this.restangular.one('public').one('partners').getList().toPromise();
  }

  getVisibleBrands(): Promise<any> {
    return this.restangular.one('users', this.getCurrentUserId()).one('partners').getList().toPromise();
  }

  enableOrDisableUserMonthlyAllowance(teamId: string, userEmail: string, canReceiveAllowance: 0 | 1): Promise<any> {
    return this.restangular.one('teams', teamId).one('user').one('email', userEmail).one('canReceiveAllowance', canReceiveAllowance).customPUT().toPromise();
  }

  public getAgents(filter: string, selectedBrandFilter: string, page: number, limit: number): Promise<any> {
    return this.restangular.one('users', this.getCurrentUserId()).one(`agents?filter=${filter}&partnerId=${selectedBrandFilter}&page=${page}&limit=${limit}`).getList().toPromise();
  }

  updateUserBrand(userId: string, partnerId: string): Promise<any> {
    const body: {partnerId: string} = { partnerId: partnerId || ''};
    return this.restangular.one('users', userId).one('partner').customPATCH(body).toPromise();
  }

  getLogins(userId: string): Promise<any> {
    return this.restangular.one('users', userId).one('logins').getList().toPromise();
  }

  enableUserAccount(userId: string): Promise<any> {
    return this.restangular.one('admin').one('users', userId).one('enableAccount').customPUT().toPromise();
  }

  disableUserAccount(userId: string): Promise<any> {
    return this.restangular.one('admin').one('users', userId).one('disableAccount').customPUT().toPromise();
  }

}
